exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-index-js": () => import("./../../../src/templates/article/index.js" /* webpackChunkName: "component---src-templates-article-index-js" */),
  "component---src-templates-category-index-js": () => import("./../../../src/templates/category/index.js" /* webpackChunkName: "component---src-templates-category-index-js" */),
  "component---src-templates-channel-index-js": () => import("./../../../src/templates/channel/index.js" /* webpackChunkName: "component---src-templates-channel-index-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/templates/contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-general-index-js": () => import("./../../../src/templates/general/index.js" /* webpackChunkName: "component---src-templates-general-index-js" */),
  "component---src-templates-homepage-index-js": () => import("./../../../src/templates/homepage/index.js" /* webpackChunkName: "component---src-templates-homepage-index-js" */),
  "component---src-templates-installation-index-js": () => import("./../../../src/templates/installation/index.js" /* webpackChunkName: "component---src-templates-installation-index-js" */),
  "component---src-templates-media-landing-index-js": () => import("./../../../src/templates/media-landing/index.js" /* webpackChunkName: "component---src-templates-media-landing-index-js" */),
  "component---src-templates-pdp-index-js": () => import("./../../../src/templates/pdp/index.js" /* webpackChunkName: "component---src-templates-pdp-index-js" */),
  "component---src-templates-pllp-index-js": () => import("./../../../src/templates/pllp/index.js" /* webpackChunkName: "component---src-templates-pllp-index-js" */),
  "component---src-templates-policies-index-js": () => import("./../../../src/templates/policies/index.js" /* webpackChunkName: "component---src-templates-policies-index-js" */)
}

