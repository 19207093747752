/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { GoogleTagManager } from './src/components/third-party/google-tagmanager';

require('./node_modules/@pella/sash-framework/dist/index.css');

// Anchor Function for initial render of page with anchor
export const onRouteUpdate = ({ location }) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'gatsby-route-change',
	});

	// close mobile menu on same page click
	if (window.innerWidth <= 1024 && window.location.href === location.href) {
		const close = document.querySelector('.header-mobileBtn');
		if (close && close.getAttribute('aria-expanded') === 'true') close.click();
	}

	if (document.body.classList.contains('overflow-hidden')) {
		document.body.classList.remove('overflow-hidden');
	}
};

export const wrapRootElement = ({ element }) => (
	<>
		{element}
		<GoogleTagManager />
	</>
);
